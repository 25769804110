import { useQuery } from '@apollo/client';
import { useApolloContext } from 'contexts';
import {
  SpaceResourceDetailsQuery,
  SpaceResourceDetailsQueryVariables,
} from 'generated';
import gql from 'graphql-tag';

const SPACE_RESOURCE_DETAILS = gql`
  query SpaceResourceDetails($id: ID!) {
    getSpaceById(id: $id) {
      id
      name
      availableAt
      description
      image
      type
      capacity
      isAccessible
      locationId
      scheduleConfiguration
    }
  }
`;

export const useSpaceResourceDetails = (spaceId: string | undefined) => {
  const { tenantId } = useApolloContext();

  const { loading, data } = useQuery<
    SpaceResourceDetailsQuery,
    SpaceResourceDetailsQueryVariables
  >(SPACE_RESOURCE_DETAILS, {
    skip: !tenantId || !spaceId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: spaceId || '',
    },
  });

  return { loading, spaceDetails: data?.getSpaceById };
};
