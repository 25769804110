import { gql } from '@apollo/client';
import { GetEditReservationSummaryQueryVariables } from 'generated';
import { GetEditReservationSummaryQuery } from 'generated';
import { useQueryCachedLoad } from 'hooks';
import { useMultiDayQueryVars } from './useMultiDayVars';
import { useApolloContext } from 'contexts';
import { useMemo } from 'react';

const GET_EDIT_RESERVATION_SUMMARY = gql`
  query GetEditReservationSummary(
    $reservationId: ID!
    $deskIds: [ID!]!
    $dates: [LocalDate!]!
    $startTime: LocalTime!
    $durationInMinutes: Int!
    $timezone: IANATimezone!
    $userId: Int!
    $startTimeMoment: Date!
    $endTimeMoment: Date!
    $recurrence: String
  ) {
    getDesksByIds(ids: $deskIds) {
      id
      name
      rawType
      level {
        id
        name
      }
      location {
        id
        name
      }
      state(
        startTime: $startTimeMoment
        endTime: $endTimeMoment
        userId: $userId
        recurrence: $recurrence
      ) {
        reservations {
          id
          startTime
        }
        exclusions {
          startTime
          endTime
        }
      }
    }
    reservationsMultiDayGroupedByDate(
      deskIds: $deskIds
      dates: $dates
      startTime: $startTime
      durationInMinutes: $durationInMinutes
      timezone: $timezone
      userId: $userId
    ) {
      deskId
      availability
      unbookableReasons {
        reason
        value
      }
    }
    getDeskReservationById(id: $reservationId) {
      id
      seriesId
      startTime
      endTime
    }
  }
`;

export const useEditReservationSummary = (
  currentDeskId: string | null | undefined,
  newDeskId: string | null,
  reservationId: string | null
) => {
  const { tenantId } = useApolloContext();
  const { skip, variables } = useMultiDayQueryVars();

  const deskIds: string[] = [];

  if (currentDeskId) {
    deskIds.push(currentDeskId);
  }

  if (newDeskId) {
    deskIds.push(newDeskId);
  }

  const { loading, data } = useQueryCachedLoad<
    GetEditReservationSummaryQuery,
    GetEditReservationSummaryQueryVariables
  >(GET_EDIT_RESERVATION_SUMMARY, {
    context: {
      headers: {
        'cache-refresh': 'no-cache',
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !tenantId || deskIds.length === 0 || !reservationId || skip,
    variables: {
      reservationId: reservationId || '',
      deskIds,
      ...variables,
    },
  });

  const [
    currentDeskData,
    currentDeskAvailability,
    newDeskData,
    newDeskAvailability,
  ] = useMemo(() => {
    const currentDesk = data?.getDesksByIds.find(
      (desk) => desk.id === currentDeskId
    );

    const currentDeskAvailability =
      data?.reservationsMultiDayGroupedByDate.find(
        (desk) => desk.deskId === currentDeskId
      );

    const newDesk = data?.getDesksByIds.find((desk) => desk.id === newDeskId);

    const newDeskAvailability = data?.reservationsMultiDayGroupedByDate.find(
      (desk) => desk.deskId === newDeskId
    );

    return [currentDesk, currentDeskAvailability, newDesk, newDeskAvailability];
  }, [data, currentDeskId, newDeskId]);

  return {
    loading,
    currentDeskData,
    currentDeskAvailability,
    newDeskData,
    newDeskAvailability,
    reservation: data?.getDeskReservationById,
  };
};
