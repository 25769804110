import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useApolloContext } from 'contexts';
import {
  SpaceWindowedAvailabilityQuery,
  SpaceWindowedAvailabilityQueryVariables,
} from 'generated';
import { LocationDateTimeMoment } from 'utils';

export type SpaceWindowAvailability =
  SpaceWindowedAvailabilityQuery['getSpaceById'];

const NUMBER_OF_WINDOWS = 4;

const SPACE_WINDOWED_AVAILABILITY = gql`
  query SpaceWindowedAvailability(
    $id: ID!
    $startTime: Date
    $numberOfWindows: Int!
  ) {
    getSpaceById(id: $id) {
      windowedAvailability(
        startTime: $startTime
        numberOfWindows: $numberOfWindows
      ) {
        start
        end
        status
      }
    }
  }
`;

export const useSpaceWindowedAvailability = (
  spaceId: string | undefined,
  startTime: LocationDateTimeMoment | undefined
) => {
  const { tenantId } = useApolloContext();

  const { loading, data } = useQuery<
    SpaceWindowedAvailabilityQuery,
    SpaceWindowedAvailabilityQueryVariables
  >(SPACE_WINDOWED_AVAILABILITY, {
    skip: !tenantId || !spaceId || !startTime,
    variables: {
      id: spaceId || '',
      numberOfWindows: NUMBER_OF_WINDOWS,
      startTime: startTime?.toISOString(),
    },
  });

  return {
    loading,
    windowedAvailability: data?.getSpaceById?.windowedAvailability,
  };
};
