import styled from '@emotion/styled';
import { AmenitiesFilter } from './AmenitiesFilter';
import { DeskFilterContextProvider } from '../../context/DeskFilterContext';

const DeskSubFiltersWithContext = () => {
  return (
    <DeskFilterContextProvider>
      <Container>
        <AmenitiesFilter />
      </Container>
    </DeskFilterContextProvider>
  );
};

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  gap: var(--Space-Margin-marginXS, 8px);
`;

export { DeskSubFiltersWithContext as DeskSubFilters };
