import { useApolloContext } from 'contexts';
import { DeskDetailsByIdQuery, DeskDetailsByIdQueryVariables } from 'generated';
import gql from 'graphql-tag';
import { useQueryCachedLoad } from '../../../../hooks';
import { useMultiDayQueryVars } from './useMultiDayVars';

export type Desk = DeskDetailsByIdQuery['getDeskById'];

export const GET_RESERVATIONS_BY_SEAT_ID = gql`
  query DeskDetailsById(
    $deskId: ID!
    $userId: Int!
    $startTimeMoment: Date!
    $endTimeMoment: Date!
    $recurrence: String
  ) {
    getDeskById(id: $deskId) {
      id
      rawType
      name
      zone {
        name
      }
      location {
        name
      }
      level {
        name
      }
      state(
        startTime: $startTimeMoment
        endTime: $endTimeMoment
        userId: $userId
        recurrence: $recurrence
      ) {
        exclusions {
          startTime
          endTime
        }
      }
    }
  }
`;

export const useDeskDetails = (deskId: string | undefined) => {
  const { tenantId } = useApolloContext();
  const { skip, variables } = useMultiDayQueryVars();
  const { startTimeMoment, endTimeMoment, recurrence, userId } = variables;

  const { loading, data } = useQueryCachedLoad<
    DeskDetailsByIdQuery,
    DeskDetailsByIdQueryVariables
  >(
    GET_RESERVATIONS_BY_SEAT_ID,
    {
      skip: !tenantId || !deskId || skip,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        deskId: deskId || '',
        startTimeMoment: startTimeMoment?.toString() || '',
        endTimeMoment: endTimeMoment?.toString() || '',
        userId,
        recurrence,
      },
    },
    [deskId]
  );

  return {
    loading,
    deskDetails: data?.getDeskById as Desk | undefined,
  };
};
