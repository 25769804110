// date format for selected dates on date picker
export const SELECTED_DATE_FORMAT = 'YYYY-MM-DD';
// time format for selected dates on time dropdown
export const SELECTED_TIME_FORMAT = 'HH:mm:ss';
export const SHORT_TIME_FORMAT = 'h:mm a';
export const LONG_DATE_FORMAT = 'dddd, MMMM D, YYYY';
export const SHORT_DATE_FORMAT = 'ddd, MMM D';
export const SLASH_DATE_FORMAT = 'dddd M/D';

// Define the formats that are considered valid
// TODO: Do we want to support any others?
export const SUPPORTED_TIME_FORMATS = [
  'h:mm a',
  'h:mm A',
  'H:mm',
  'h:mma',
  'h:mm',
];
