import { List } from '@robinpowered/ui-kit';
import { DeskListItem } from './DeskListItem';
import { DeskWithAvailability } from './types';

export const ZoneList = ({ desks }: { desks: DeskWithAvailability[] }) => {
  const items = [...desks].sort((a, b) =>
    a.name.localeCompare(b.name, undefined, { numeric: true })
  );

  return (
    <List
      dataSource={items}
      renderItem={(deskData) => (
        <List.Item data-testid="zone-list-item">
          <DeskListItem desk={deskData} />
        </List.Item>
      )}
    />
  );
};
