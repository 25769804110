import { useApolloContext } from 'contexts';
import {
  DeskReservationsBySeatIdQuery,
  DeskReservationsBySeatIdQueryVariables,
} from 'generated';
import gql from 'graphql-tag';
import { useQueryCachedLoad } from '../../../../hooks';
import { Moment } from 'moment';

export enum HealthCheckpointStatus {
  Complete = 'COMPLETE',
  Disabled = 'DISABLED',
  Exempt = 'EXEMPT',
  Failed = 'FAILED',
  Incomplete = 'INCOMPLETE',
  NotAvailable = 'NOT_AVAILABLE',
  Passed = 'PASSED',
}

export type DeskReservationsBySeatId =
  DeskReservationsBySeatIdQuery['getDeskReservationsBySeatId'];
export type Reservation = DeskReservationsBySeatId[number];

const DESK_RESERVATIONS_BY_SEAT_ID = gql`
  query DeskReservationsBySeatId(
    $deskId: ID!
    $startTime: ISODateTime!
    $duration: Int!
  ) {
    getDeskReservationsBySeatId(
      deskId: $deskId
      startTime: $startTime
      duration: $duration
    ) {
      id
      seriesId
      startTime
      endTime
      timeZone
      canCheckInNow
      isCheckedIn
      visibility
      reservee {
        user {
          id
          name
          avatar
          primaryEmail {
            email
          }
        }
      }
      healthCheckpoint {
        status
      }
    }
  }
`;

export const useDeskReservationsBySeatId = (
  deskId: string | undefined,
  duration: number,
  startTime?: Moment
) => {
  const { tenantId } = useApolloContext();

  const { loading, data } = useQueryCachedLoad<
    DeskReservationsBySeatIdQuery,
    DeskReservationsBySeatIdQueryVariables
  >(
    DESK_RESERVATIONS_BY_SEAT_ID,
    {
      skip: !tenantId || !deskId || !startTime,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        deskId: deskId || '',
        startTime: startTime?.toISOString(),
        duration,
      },
    },
    [deskId]
  );

  return {
    loading,
    reservations: data?.getDeskReservationsBySeatId ?? [],
  };
};
