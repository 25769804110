import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';
import { DependencyList, useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

/**
 * useQuery hook with additional features:
 * - Loading will only be true on the first request
 * - Data will not become undefined on subsequent queries
 * - Accepts a dependency array as the third option, and if any those watched values change the cache WILL be busted
 *
 * Notes:
 * - Calls the standard apollo/client userQuery hook under the hood
 * - refetch will bust the loading/data caches
 */
export const useQueryCachedLoad = <
  TData,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> | undefined,
  bustCacheDeps?: DependencyList
): QueryResult<TData, TVariables> => {
  // If any watched values change, bust the cache
  useEffect(() => {
    setCachedLoading(true);
    setCachedData(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(bustCacheDeps || [])]);

  const result = useQuery<TData, TVariables>(query, options);

  const [cachedLoading, setCachedLoading] = useState<boolean>(true);
  const [cachedData, setCachedData] = useState<TData | undefined>(undefined);

  // Handle the caching of loading and data responses
  useEffect(() => {
    if (result && !result.loading) {
      if (cachedLoading === true) {
        setCachedLoading(false);
      }

      if (!isEqual(cachedData, result.data)) {
        setCachedData(result.data);
      }
    }
  }, [cachedData, cachedLoading, result]);

  const refetchWithBustedCache = useCallback(() => {
    setCachedLoading(true);
    setCachedData(undefined);

    return result.refetch();
  }, [result]);

  return {
    ...result,
    loading: cachedLoading,
    data: cachedData,
    refetch: refetchWithBustedCache,
  };
};
