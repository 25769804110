import { useCallback, useMemo } from 'react';
import { User } from 'contexts';
import { DeskReservationVisibility } from 'generated';
import { SharedDeskSchedules } from '../graphql/useSharedDeskSchedule';
import { Desk, DeskReservationsBySeatId } from '../graphql';
import { DeskSettings } from '../graphql/useDeskSettings';
import { DeskReservationCard } from './DeskReservationCard';
import { useReservationActions } from './hooks';

interface NonScheduledReservationsProps {
  deskSchedules: SharedDeskSchedules | undefined;
  reservations: DeskReservationsBySeatId | undefined;
  deskSettings: DeskSettings;
  desk: Desk;
  currentUser: User | null;
}

export function NonScheduledReservations({
  deskSchedules,
  reservations,
  deskSettings,
  desk,
  currentUser,
}: NonScheduledReservationsProps): JSX.Element | null {
  const {
    allowCheckIns,
    enforceLocalCheckInOnly,
    allowExclusions,
    getCheckInStart,
    getDeskAbandonedAt,
    handleCheckIn,
    checkinLoading,
    hasReservationStarted,
  } = useReservationActions(deskSettings);

  const showReservee = useCallback(
    ({ visibility, reservee }) =>
      visibility === DeskReservationVisibility.Everyone ||
      reservee?.user?.id === currentUser?.id,
    [currentUser?.id]
  );

  const filteredReservations = useMemo(() => {
    return reservations?.filter((reservation) => {
      const isScheduled = deskSchedules?.some((sharedDeskSchedule) =>
        sharedDeskSchedule?.schedule?.some((schedule) => {
          return reservation.reservee?.user?.id === schedule.id;
        })
      );
      return !isScheduled;
    });
  }, [reservations, deskSchedules]);

  return (
    <>
      {filteredReservations?.map((reservation) => (
        <DeskReservationCard
          key={reservation.id}
          reservation={reservation}
          desk={desk}
          currentUser={currentUser}
          showUserDetails={showReservee(reservation)}
          showReservationDetails={true}
          getCheckInStart={getCheckInStart}
          getDeskAbandonedAt={getDeskAbandonedAt}
          handleCheckIn={handleCheckIn}
          checkinLoading={checkinLoading}
          allowCheckIns={allowCheckIns}
          enforceLocalCheckInOnly={enforceLocalCheckInOnly}
          allowExclusions={allowExclusions}
          hasReservationStarted={hasReservationStarted}
          schedule={null}
        />
      ))}
    </>
  );
}
