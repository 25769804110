import { useCallback, useEffect, useState } from 'react';
import { config } from 'config';
import { useTranslation } from 'react-i18next';
import { DeskReservationReservee } from 'components/resource-details/desk/graphql/useDeskReservationReservee';

export const useShareUrl = (
  reservations: DeskReservationReservee | undefined
) => {
  const { t } = useTranslation('resourceDetails');
  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const copyButtonText = copySuccess
    ? t('buttons.link_copied')
    : t('buttons.copy_link');

  const buildShareUrl = (id: string): string => {
    return `${config.shareUrl}/resource/${id}`;
  };

  useEffect(() => {
    let ts: ReturnType<typeof setTimeout> | null = null;
    if (copySuccess) {
      ts = setTimeout(() => setCopySuccess(false), 2000);
    }

    return (): void => {
      if (ts) {
        clearTimeout(ts);
      }
    };
  }, [copySuccess]);

  const copyShareLink = useCallback(async () => {
    // eslint-disable-no-floating-promises
    reservations &&
      (await navigator.clipboard.writeText(buildShareUrl(reservations[0].id)));
    setCopySuccess(true);
  }, [reservations]);

  return {
    copyButtonText,
    copyShareLink,
  };
};
