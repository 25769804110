import { useCallback } from 'react';
import moment from 'moment';
import { RRule } from 'rrule';
import { DeskSettings } from '../graphql/useDeskSettings';
import { SharedDeskSchedules } from '../graphql/useSharedDeskSchedule';
import { Desk, DeskReservationsBySeatId } from '../graphql';
import { User } from 'contexts';
import { DeskReservationCard } from './DeskReservationCard';
import { useReservationActions } from './hooks';
import { useGetStartTimesForSelectedDates } from 'atoms/resource';

interface ScheduledReservationsProps {
  deskSchedules: SharedDeskSchedules | undefined;
  reservations: DeskReservationsBySeatId | undefined;
  deskSettings: DeskSettings;
  desk: Desk;
  currentUser: User | null;
}

export function ScheduledReservations({
  deskSchedules,
  reservations,
  deskSettings,
  desk,
  currentUser,
}: ScheduledReservationsProps): JSX.Element | null {
  const startTimes = useGetStartTimesForSelectedDates();

  const calculateIsDuringExclusion = useCallback(
    (scheduleRecurrence: string) => {
      if (!startTimes || startTimes.length === 0) {
        return false;
      }

      const allExclusions = desk?.state.exclusions;
      if (!allExclusions || allExclusions.length === 0) {
        return false;
      }

      const rrule = RRule.fromString(scheduleRecurrence);
      const scheduleDays = rrule.options.byweekday;

      const isStartTimeInSchedule = startTimes.some((startTime) =>
        scheduleDays?.includes(startTime.day())
      );

      if (!isStartTimeInSchedule) {
        return false;
      }

      return allExclusions.some((exclusion) => {
        const exclusionStart = moment(exclusion.startTime);
        const exclusionEnd = moment(exclusion.endTime);
        return startTimes.some((startTime) =>
          startTime.isBetween(exclusionStart, exclusionEnd)
        );
      });
    },
    [startTimes, desk]
  );

  const {
    allowCheckIns,
    enforceLocalCheckInOnly,
    allowExclusions,
    getCheckInStart,
    getDeskAbandonedAt,
    handleCheckIn,
    checkinLoading,
    hasReservationStarted,
  } = useReservationActions(deskSettings);

  return (
    <>
      {deskSchedules?.map((sharedSchedule) =>
        sharedSchedule?.schedule?.map((schedule) => {
          const matchingReservation = reservations?.find(
            (reservation) => reservation.reservee?.user?.id === schedule.id
          );

          const isDuringExclusion = calculateIsDuringExclusion(
            schedule.recurrence
          );

          return (
            <DeskReservationCard
              key={schedule.id}
              reservation={matchingReservation}
              schedule={schedule}
              desk={desk}
              currentUser={currentUser}
              isDuringExclusion={isDuringExclusion}
              showUserDetails={true}
              showReservationDetails={false}
              getCheckInStart={getCheckInStart}
              getDeskAbandonedAt={getDeskAbandonedAt}
              handleCheckIn={handleCheckIn}
              checkinLoading={checkinLoading}
              allowCheckIns={allowCheckIns}
              enforceLocalCheckInOnly={enforceLocalCheckInOnly}
              allowExclusions={allowExclusions}
              hasReservationStarted={hasReservationStarted}
            />
          );
        })
      )}
    </>
  );
}
