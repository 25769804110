import React, { useState } from 'react';

import { CancelReservationModal } from 'components/cancel-reservation-modal';
import moment from 'moment';
import { RRule } from 'rrule';
import { DeskReservationVisibility, DeskSchedule } from 'generated';
import { useTranslation } from 'react-i18next';
import { User } from 'contexts';
import { Desk, Reservation } from '../graphql';
import { AssignedDeskSchedule } from './AssignedDeskSchedule';
import { CheckInStatus } from './CheckInStatus';
import { ReservationDetails } from './ReservationDetails';
import { UserDetails } from './UserDetails';
import styled from '@emotion/styled';
import { Typography } from '@robinpowered/ui-kit';
import { LocationDateTimeMoment, momentToLocationDateTime } from 'utils';
import { useTimezone } from 'atoms/resource';

const { Text } = Typography;

interface DeskReservationCardProps {
  reservation: Reservation | undefined;
  desk: Desk;
  currentUser: User | null;
  isDuringExclusion?: boolean;
  schedule: DeskSchedule | null;
  showUserDetails?: boolean;
  isSharedDesk?: boolean;
  checkinLoading: boolean;
  allowCheckIns: boolean;
  enforceLocalCheckInOnly: boolean;
  allowExclusions: boolean;
  showReservationDetails: boolean;
  getCheckInStart: (reservation: Reservation) => LocationDateTimeMoment;
  getDeskAbandonedAt: (
    reservation: Reservation
  ) => LocationDateTimeMoment | null;
  handleCheckIn: (reservationId: string) => void;
  hasReservationStarted: (reservation: Reservation) => boolean;
}

export function DeskReservationCard({
  reservation,
  desk,
  currentUser,
  isDuringExclusion,
  schedule,
  showUserDetails,
  checkinLoading,
  allowCheckIns,
  enforceLocalCheckInOnly,
  allowExclusions,
  showReservationDetails,
  hasReservationStarted,
  getCheckInStart,
  getDeskAbandonedAt,
  handleCheckIn,
}: DeskReservationCardProps) {
  const { t } = useTranslation('resourceDetails');
  const { timezone } = useTimezone();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  return (
    <DeskReservationsContainer key={reservation?.id || schedule?.id}>
      <DeskReservationContainer>
        <DeskReservationSubContainer>
          {isDuringExclusion && (
            <ItalicText>{t('usually_sits_here')}</ItalicText>
          )}
          {schedule && (
            <AssignedDeskSchedule
              key={schedule.id}
              recurrence={RRule.fromString(schedule.recurrence)}
            />
          )}
          {showUserDetails && (
            <UserDetails
              avatarSrc={
                schedule
                  ? schedule.avatar || ''
                  : reservation?.reservee?.user?.avatar || ''
              }
              name={
                schedule
                  ? `${schedule.firstName} ${schedule.lastName}`
                  : reservation?.reservee?.user?.name || ''
              }
              email={
                schedule
                  ? schedule.email
                  : reservation?.reservee?.user?.primaryEmail?.email || ''
              }
            />
          )}
          {reservation && showReservationDetails && (
            <ReservationDetails
              startTime={reservation.startTime}
              seriesId={reservation.seriesId}
              endTime={reservation.endTime}
              timezone={reservation.timeZone}
              isPrivate={
                reservation.visibility === DeskReservationVisibility.JustMe
              }
            />
          )}
          {reservation && (
            <>
              <CheckInStatus
                deskId={desk?.id}
                seriesId={reservation.seriesId}
                deskType={desk?.rawType}
                isUsersReservation={
                  reservation.reservee?.user?.id === currentUser?.id
                }
                isSeries={!!reservation.seriesId}
                canCheckIn={allowCheckIns}
                canCheckInNow={!!reservation.canCheckInNow}
                healthCheckpointStatus={reservation.healthCheckpoint?.status}
                isCheckedIn={!!reservation.isCheckedIn}
                localCheckInOnly={enforceLocalCheckInOnly}
                checkInStart={getCheckInStart(reservation)}
                deskAbandonedAt={getDeskAbandonedAt(reservation)}
                allowExclusions={allowExclusions}
                onCheckIn={() => handleCheckIn(reservation.id)}
                checkinLoading={checkinLoading}
                onCancel={() => setCancelModalOpen(true)}
                reservationId={reservation.id}
                reservationInProgress={hasReservationStarted(reservation)}
              />
              <CancelReservationModal
                onClose={() => setCancelModalOpen(false)}
                isOpen={cancelModalOpen}
                onConfirm={() => setCancelModalOpen(false)}
                reservationInProgress={hasReservationStarted(reservation)}
                reservationUserId={reservation.reservee?.user?.id}
                reservationUserName={reservation.reservee?.user?.name}
                reservationStartDate={momentToLocationDateTime(
                  moment(reservation.startTime),
                  timezone
                )}
                reservationTimezone={timezone}
                reservationId={reservation.id}
                reservationSeriesId={reservation.seriesId}
                deskName={desk ? desk.name : ''}
                buildingName={desk ? desk.location.name : ''}
                floorName={desk?.level?.name}
              />
            </>
          )}
        </DeskReservationSubContainer>
      </DeskReservationContainer>
    </DeskReservationsContainer>
  );
}

export const DeskReservationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;

  border-radius: var(--Border-Radius-borderRadius, 4px);
  border: 1px solid var(--Colors-Neutral-Border-colorBorder, #d9d9d9);
`;

export const DeskReservationContainer = styled.div`
  display: flex;
  padding: var(--Space-Padding-paddingSM, 12px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
`;

export const DeskReservationSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Padding-paddingSM, 12px);
  align-self: stretch;
`;

export const ItalicText = styled(Text)`
  font-style: italic;
`;
