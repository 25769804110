import { Avatar, Typography } from '@robinpowered/ui-kit';
import { MinorDesk } from '@robinpowered/icons';
import styled from '@emotion/styled';
import { DeskWithAvailability } from './types';

export const DeskListItem = ({ desk }: { desk: DeskWithAvailability }) => {
  return (
    <DeskListItemContainer>
      <Avatar icon={<MinorDesk color={'white'} size={18} />} />

      <DeskListItemTextContainer>
        <DeskListItemTextTitle>{desk.name}</DeskListItemTextTitle>
        <DeskListItemTextSubtitle>
          {desk.isBookable ? 'Available' : 'Unavailable'}
        </DeskListItemTextSubtitle>
      </DeskListItemTextContainer>
    </DeskListItemContainer>
  );
};

const DeskListItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--Components-List-Component-avatarMarginRight, 16px);
`;

const DeskListItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const DeskListItemTextTitle = styled(Typography.Text)`
  color: var(--Components-List-Global-colorText, #1c1c1c);
`;

const DeskListItemTextSubtitle = styled(Typography.Text)`
  color: var(--Components-List-Global-colorTextDescription, #6c6c6c);
`;
