import { gql, useQuery } from '@apollo/client';
import { Select } from '@robinpowered/ui-kit';
import { useSelectedLevel } from 'atoms/resource';
import { useCallback, useMemo, useState } from 'react';
import { CapacityGroup, isCapacityGroup, SelectOption } from '../../types';
import { useTranslation } from 'react-i18next';
import {
  capacityToSelectOptions,
  getSpaceIdsThatAreWithCapacityGroupRange,
} from './utils/capacity';
import {
  SpaceCapacityFilterQuery,
  SpaceCapacityFilterQueryVariables,
} from 'generated';
import { useSpaceFilterContext } from '../../context/SpaceFilterContext';

const CAPACITY_FILTER_QUERY = gql`
  query spaceCapacityFilter($levelId: ID!) {
    getLevelById(id: $levelId) {
      spaces {
        id
        capacity
      }
    }
  }
`;

export type Spaces = NonNullable<
  SpaceCapacityFilterQuery['getLevelById']
>['spaces'];

export const CapacityFilter = () => {
  const { t } = useTranslation('filters');
  const [levelId] = useSelectedLevel();
  const [selectedCapacityGroup, setSelectedCapacityGroup] = useState<
    CapacityGroup | undefined
  >(undefined);
  const { setSpaceIdsThatMatchCapacityCriteria } = useSpaceFilterContext();

  const { loading, data } = useQuery<
    SpaceCapacityFilterQuery,
    SpaceCapacityFilterQueryVariables
  >(CAPACITY_FILTER_QUERY, {
    skip: !levelId,
    variables: {
      levelId: levelId || '',
    },
  });

  const options: SelectOption[] = useMemo(
    () => capacityToSelectOptions(data?.getLevelById?.spaces),
    [data?.getLevelById?.spaces]
  );

  const handleChange = useCallback(
    (updatedCapacityGroupString) => {
      // Handle the user clearing out the selection
      if (updatedCapacityGroupString === undefined) {
        setSelectedCapacityGroup(undefined);

        setSpaceIdsThatMatchCapacityCriteria(
          data?.getLevelById?.spaces?.map((space) => space.id) || []
        );

        return;
      }

      let updatedCapacityGroup: CapacityGroup | undefined = undefined;

      try {
        updatedCapacityGroup = JSON.parse(updatedCapacityGroupString);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      if (isCapacityGroup(updatedCapacityGroup)) {
        setSelectedCapacityGroup(updatedCapacityGroup);

        setSpaceIdsThatMatchCapacityCriteria(
          getSpaceIdsThatAreWithCapacityGroupRange(
            data?.getLevelById?.spaces,
            updatedCapacityGroup
          )
        );
      }
    },
    [data?.getLevelById?.spaces, setSpaceIdsThatMatchCapacityCriteria]
  );

  return (
    <Select
      optionFilterProp="label"
      loading={loading}
      value={JSON.stringify(selectedCapacityGroup)}
      style={{ width: '200px' }}
      onChange={handleChange}
      options={options}
      placeholder={t('SPACE_FILTERS.FILTER_LABELS.CAPACITY')}
      allowClear={true}
      data-testid="space-capacity-filter"
    />
  );
};
