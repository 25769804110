import {
  SeatStateSingleDayComponnentProgressiveLoad,
  SpaceStateComponentProgressiveLoad,
  ResourceStateFilterComponent,
  SeatStateMultiDayComponnentProgressiveLoad,
  YourDeskMarker,
  SeatAvatarComponent,
} from '@robinpowered/perseus';
import {
  useGetStartTimesForSelectedDates,
  useGetSpaceEndTimesForSelectedDates,
  useTimezone,
} from 'atoms/resource';
import {
  useCurrentFilter,
  useSpaceFilterInitialized,
  useFilteredSpaceIds,
  useMapInteractiveLayers,
  useDeskFilterInitialized,
  useFilteredDeskIds,
} from 'atoms/mapInteractions';
import { useMemo } from 'react';
import { useAuthContext } from 'contexts';
import { useTranslation } from 'react-i18next';
import { useMultiDayQueryVars } from 'components/resource-details/desk/graphql/useMultiDayVars';
import moment from 'moment';

export const MapData = () => {
  const { t } = useTranslation('map');
  const { timezone } = useTimezone();
  const {
    variables: {
      startTimeMoment,
      endTimeMoment,
      durationInMinutes: deskDuration,
    },
  } = useMultiDayQueryVars();
  const { currentUser, permissions } = useAuthContext();
  const mapInteractiveLayers = useMapInteractiveLayers();
  const currentFilter = useCurrentFilter();
  const filteredSpaceIds = useFilteredSpaceIds();
  const spaceFilterInitialized = useSpaceFilterInitialized();
  const filteredDeskIds = useFilteredDeskIds();
  const deskFilterInitialized = useDeskFilterInitialized();

  const startTimes = useGetStartTimesForSelectedDates();
  const availabilityStartTime = useMemo(() => {
    if (startTimes && startTimes.length > 0) {
      return startTimes[0];
    }
    return null;
  }, [startTimes]);

  const endSpaceTimes = useGetSpaceEndTimesForSelectedDates();
  const endTimeForSpaces = useMemo(() => {
    if (endSpaceTimes && endSpaceTimes.length > 0) {
      return endSpaceTimes[0];
    }
    return null;
  }, [endSpaceTimes]);

  const spaceAvailabilityDuration = useMemo(
    () =>
      (availabilityStartTime &&
        endTimeForSpaces &&
        endTimeForSpaces.diff(availabilityStartTime, 'minutes')) ||
      undefined,
    [availabilityStartTime, endTimeForSpaces]
  );

  const multiDayAvailabilityDates = useGetStartTimesForSelectedDates() || [];

  const excludedUsers = currentUser ? [currentUser.id] : [];
  const deskStartTime =
    startTimeMoment !== ''
      ? moment(startTimeMoment).toISOString().replace(/\.\d+/, '')
      : null;
  const deskEndTime =
    endTimeMoment !== ''
      ? moment(endTimeMoment).toISOString().replace(/\.\d+/, '')
      : null;
  return (
    <>
      {mapInteractiveLayers.has('seats') &&
        multiDayAvailabilityDates.length === 1 &&
        deskStartTime &&
        deskEndTime && (
          <SeatAvatarComponent
            excludeUserIdentities={new Set(excludedUsers)}
            startTime={deskStartTime}
            endTime={deskEndTime}
            zoomLimit={{
              low: 2,
              medium: 3,
              high: 4.5,
            }}
            showAnonymous={permissions.canReserveDesksForOthers}
          />
        )}
      {mapInteractiveLayers.has('seats') &&
        currentUser &&
        currentUser.id &&
        (multiDayAvailabilityDates.length > 1 ? (
          <SeatStateMultiDayComponnentProgressiveLoad
            startTime={multiDayAvailabilityDates[0].format('H:m')}
            durationInMinutes={deskDuration || 15}
            dates={multiDayAvailabilityDates.map((x) => x.format('YYYY-MM-DD'))}
            timezone={timezone}
            seatPerLoadCycle={10}
            userId={Number(currentUser.id)}
          />
        ) : multiDayAvailabilityDates.length === 1 &&
          deskStartTime &&
          deskEndTime ? (
          <SeatStateSingleDayComponnentProgressiveLoad
            seatPerLoadCycle={10}
            startTime={deskStartTime}
            endTime={deskEndTime}
            userId={Number(currentUser.id)}
          />
        ) : (
          <></>
        ))}

      {(mapInteractiveLayers.has('spaces') ||
        mapInteractiveLayers.has('space_labels')) &&
        multiDayAvailabilityDates.length > 0 && (
          <SpaceStateComponentProgressiveLoad
            spacePerLoadCycle={10}
            startTime={multiDayAvailabilityDates[0]
              .toISOString()
              .replace(/\.\d+/, '')}
            duration={spaceAvailabilityDuration}
            dates={multiDayAvailabilityDates.map((x) =>
              x.toISOString().replace(/\.\d+/, '')
            )}
          />
        )}

      {currentFilter === 'spaces' && spaceFilterInitialized && (
        <ResourceStateFilterComponent
          resourceIds={filteredSpaceIds}
          layer="spaces"
        />
      )}

      {currentFilter === 'seats' && deskFilterInitialized && (
        <ResourceStateFilterComponent
          resourceIds={filteredDeskIds}
          layer="seats"
        />
      )}

      {deskStartTime && deskEndTime && currentUser && (
        <YourDeskMarker
          startTime={deskStartTime}
          endTime={deskEndTime}
          userId={currentUser.id}
          text={t('your_desk')}
        />
      )}
    </>
  );
};
