import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Space, Typography } from '@robinpowered/ui-kit';
import { Add } from '@robinpowered/icons';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  useSetStartTime,
  useSetEndTimeFromStartTimeForSpaces,
  useSetEndTimeFromStartTimeForDesks,
  startTimeState,
} from 'atoms/resource';
import { useCurrentFilter } from 'atoms/mapInteractions';
import { rightSidebarView } from 'atoms/sidebar/atoms';
import { LocationDateTimeMoment } from '../../../utils';
import { LocationSelector } from './LocationSelector';
import { LevelSelector } from './LevelSelector';
import { DatePickerComponent, EndTimeDropdown } from '../../global/controls';
import { StartTimeDropdown } from 'components/global/controls/StartTimeDropdown';
import { OfficeClosedBanner } from 'components/map/OfficeClosedBanner';
import { OfficeHoursModal } from '../OfficeHoursModal';
import { useLocationWorkingHours } from 'hooks/useLocationWorkingHours';

export const MapControls = () => {
  const { t } = useTranslation('mapControls');
  const currentFilter = useCurrentFilter();
  const [officeHoursModalOpen, setOfficeHoursModalOpen] = useState(false);
  const [officeIsOpen, setOfficeIsOpen] = useState<boolean>(true);
  const [manuallyShowEndTime, setManuallyShowEndTime] =
    useState<boolean>(false);

  const setStartTime = useSetStartTime();
  const setEndTimeForSpaces = useSetEndTimeFromStartTimeForSpaces();
  const setEndTimeForDesks = useSetEndTimeFromStartTimeForDesks();
  const { location, loading: locationLoading } = useLocationWorkingHours();
  const selectedStartTime = useRecoilValue(startTimeState);
  const sidebarOpen = useRecoilValue(rightSidebarView);

  useEffect(() => {
    if (location && !locationLoading) {
      const isOpen = Boolean(location?.state?.isOpen);
      setOfficeIsOpen(isOpen);
    }
  }, [location, locationLoading]);

  const showEndTime = useMemo(
    () => manuallyShowEndTime || !!currentFilter,
    [currentFilter, manuallyShowEndTime]
  );

  const toggleManuallyShowEndTime = useCallback(() => {
    setManuallyShowEndTime((prev) => !prev);
  }, []);

  const handleStartTimeSelect = useCallback(
    (selectedTime: LocationDateTimeMoment) => {
      setStartTime(selectedTime);
      setEndTimeForSpaces(selectedTime);
      setEndTimeForDesks(selectedTime);
    },
    [setStartTime, setEndTimeForSpaces, setEndTimeForDesks]
  );

  const openOfficeHoursModal = () => {
    setOfficeHoursModalOpen(true);
  };

  const closeOfficeHoursModal = () => {
    setOfficeHoursModalOpen(false);
  };

  return (
    <Fragment>
      <MapControlsContainer>
        <Space size={'small'} align="center">
          <LocationSelector />

          <LevelSelector />

          {!sidebarOpen && (
            <Fragment>
              <DatePickerComponent />

              <StartTimeDropdown onTimeSelect={handleStartTimeSelect} />

              {showEndTime && (
                <>
                  {/* Dash between TimeDropdown components */}
                  {/* Wrapped in t function to sidestep no hardcoded content lint error */}
                  <Typography.Text>{t('-')}</Typography.Text>

                  <EndTimeDropdown
                    type={currentFilter === 'seats' ? currentFilter : 'spaces'}
                  />
                  {!currentFilter && (
                    <Button type="text" onClick={toggleManuallyShowEndTime}>
                      <Typography.Text>{t(`CLEAR`)}</Typography.Text>
                    </Button>
                  )}
                </>
              )}

              {!showEndTime && (
                <Button type="text" onClick={toggleManuallyShowEndTime}>
                  <ButtonContainer>
                    <Add size={12} />
                    <Typography.Text>{t(`END_TIME`)}</Typography.Text>
                  </ButtonContainer>
                </Button>
              )}
            </Fragment>
          )}
        </Space>
      </MapControlsContainer>
      {!officeIsOpen && (
        <Fragment>
          <OfficeClosedBanner openOfficeHoursModal={openOfficeHoursModal} />
          <OfficeHoursModal
            onClose={closeOfficeHoursModal}
            isOpen={officeHoursModalOpen}
            location={location}
            selectedStartTime={selectedStartTime}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const MapControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;
