/* eslint-disable @shopify/jsx-no-hardcoded-content */
import { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { InfoSolid, EditAlt, TrashOutline } from '@robinpowered/icons';
import SvgCheckmark from '@robinpowered/icons/Checkmark';
import { Button, Modal, Typography } from '@robinpowered/ui-kit';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { DeskType } from 'generated';
import { HealthCheckpointStatus } from '../graphql/useDeskReservationsBySeatId';
import { SHORT_TIME_FORMAT } from 'constants/timeFormat';
import { ReleaseDeskModalProvider } from 'components/release-desk-modal/contexts';
import { ReleaseDeskModalContent } from 'components/release-desk-modal';
import { useSetIsCameraResetting, useSetMapMode } from 'atoms/mapInteractions';
import {
  useSetEditDeskReservationId,
  useSetEditDeskView,
} from 'atoms/editDesk';

const { Text } = Typography;

interface CheckInStatusProps {
  deskId: string | undefined;
  seriesId: string | undefined | null;
  deskType?: DeskType[];
  isUsersReservation: boolean;
  isCheckedIn: boolean;
  canCheckIn: boolean;
  canCheckInNow: boolean;
  healthCheckpointStatus: HealthCheckpointStatus | undefined;
  localCheckInOnly: boolean;
  checkInStart: Moment | null;
  deskAbandonedAt: Moment | null;
  allowExclusions: boolean;
  onCheckIn: () => void;
  checkinLoading: boolean;
  onCancel: () => void;
  reservationId: string;
  reservationInProgress: boolean;
  isSeries: boolean;
}

export function CheckInStatus({
  deskId,
  seriesId,
  deskType,
  isUsersReservation,
  isCheckedIn,
  canCheckIn,
  canCheckInNow,
  healthCheckpointStatus,
  localCheckInOnly,
  checkInStart,
  deskAbandonedAt,
  allowExclusions,
  onCheckIn,
  checkinLoading,
  onCancel,
  reservationId,
  reservationInProgress,
  isSeries,
}: CheckInStatusProps) {
  const { t } = useTranslation('resourceDetails');
  const setDeskSidebarView = useSetEditDeskView();
  const [isReleaseDeskOpen, setReleaseDeskOpen] = useState<boolean>(false);
  const setEditDeskReservationId = useSetEditDeskReservationId();
  const setMapMode = useSetMapMode();
  const setIsCameraResetting = useSetIsCameraResetting();

  const healthCheckpointMessage = useMemo(() => {
    if (healthCheckpointStatus === HealthCheckpointStatus.Incomplete) {
      return canCheckIn
        ? t('check_in.health_checkpoint_and_check_in_required')
        : t('check_in.health_checkpoint_required');
    } else if (healthCheckpointStatus === HealthCheckpointStatus.Complete) {
      return t('check_in.health_checkpoint_complete');
    } else if (healthCheckpointStatus === HealthCheckpointStatus.Failed) {
      return t('check_in.health_checkpoint_failed');
    }
  }, [canCheckIn, healthCheckpointStatus, t]);

  const checkInMessage = useMemo(() => {
    if (localCheckInOnly && !isCheckedIn && canCheckIn) {
      return t('check_in.local_check_in');
    }

    if (!isCheckedIn && canCheckIn && !canCheckInNow && checkInStart) {
      if (deskAbandonedAt) {
        return t('check_in.check_in_window', {
          after: checkInStart.format(SHORT_TIME_FORMAT),
          before: deskAbandonedAt.format(SHORT_TIME_FORMAT),
        });
      } else {
        return t('check_in.check_in_after', {
          time: checkInStart.format(SHORT_TIME_FORMAT),
        });
      }
    }
    return null;
  }, [
    isCheckedIn,
    canCheckIn,
    canCheckInNow,
    checkInStart,
    deskAbandonedAt,
    localCheckInOnly,
    t,
  ]);

  const cancelMessage = useMemo(() => {
    if (!isSeries) {
      return reservationInProgress ? t('check_in.end') : t('check_in.cancel');
    } else {
      return reservationInProgress
        ? t('check_in.series_end')
        : t('check_in.series_cancel');
    }
  }, [isSeries, reservationInProgress, t]);

  const onEdit = useCallback(() => {
    setDeskSidebarView('edit-summary');
    setMapMode('edit-desk-reservation');
    setEditDeskReservationId(reservationId);
    setIsCameraResetting(true);
  }, [
    reservationId,
    setEditDeskReservationId,
    setIsCameraResetting,
    setMapMode,
    setDeskSidebarView,
  ]);

  const isAssignedAndShared = useMemo(() => {
    return (
      deskType?.length === 2 &&
      deskType.includes(DeskType.Assigned) &&
      deskType.includes(DeskType.Shared)
    );
  }, [deskType]);

  if (!isCheckedIn && !canCheckIn) {
    return null;
  }

  if (isCheckedIn) {
    return (
      <CheckInContainer>
        <StatusContainer>
          <IconContainer>
            <CheckmarkIcon data-testid="checkmark-icon" />
          </IconContainer>
          <DarkGreenText data-testid="checked-in-message">
            {t('check_in.checked_in_today')}
          </DarkGreenText>
        </StatusContainer>
        {!isAssignedAndShared && isUsersReservation && (
          <CheckInButtonContainer>
            <CheckInAction
              data-testid="edit-button"
              type="default"
              onClick={onEdit}
            >
              <EditAlt size={16} />
              {t('check_in.edit')}
            </CheckInAction>
            <CheckInAction
              data-testid="cancel-button"
              type="default"
              danger
              onClick={onCancel}
            >
              <RedTrashIconContainer>
                <TrashOutline />
              </RedTrashIconContainer>
              {cancelMessage}
            </CheckInAction>
          </CheckInButtonContainer>
        )}
      </CheckInContainer>
    );
  }

  // Display checked in status even if not your reservation, otherwise return nothing
  if (!isUsersReservation) {
    return null;
  }

  return (
    <CheckInContainer>
      {healthCheckpointMessage && (
        <StatusContainer>
          {healthCheckpointStatus === HealthCheckpointStatus.Complete ? (
            <>
              <IconContainer>
                <CheckmarkIcon data-testid="checkmark-icon" />
              </IconContainer>
              <DarkGreenText data-testid="health-checkpoint-message">
                {healthCheckpointMessage}
              </DarkGreenText>
            </>
          ) : (
            <>
              <IconContainer>
                <InfoIcon data-testid="info-icon" />
              </IconContainer>
              <Text data-testid="health-checkpoint-message">
                {healthCheckpointMessage}
              </Text>
            </>
          )}
        </StatusContainer>
      )}
      {checkInMessage && (
        <StatusContainer>
          <IconContainer>
            <InfoIcon data-testid="info-icon" />
          </IconContainer>
          <Text data-testid="check-in-message">{checkInMessage}</Text>
        </StatusContainer>
      )}
      {healthCheckpointStatus === HealthCheckpointStatus.Incomplete && (
        <CheckInButtonContainer>
          <CheckInAction data-testid="health-checkpoint-button" type="primary">
            {t('check_in.start_health_checkpoint')}
          </CheckInAction>
        </CheckInButtonContainer>
      )}
      {canCheckInNow &&
        (healthCheckpointStatus === HealthCheckpointStatus.Passed ||
          healthCheckpointStatus === HealthCheckpointStatus.NotAvailable ||
          healthCheckpointStatus === HealthCheckpointStatus.Disabled) && (
          <CheckInButtonContainer>
            {!localCheckInOnly && (
              <CheckInAction
                data-testid="check-in-button"
                type="primary"
                onClick={onCheckIn}
                loading={checkinLoading}
              >
                {t('check_in.check_in_now')}
              </CheckInAction>
            )}

            {allowExclusions && (
              <>
                <CheckInAction
                  data-testid="release-button"
                  type="default"
                  onClick={() => {
                    setReleaseDeskOpen(true);
                  }}
                >
                  {t('check_in.release')}
                </CheckInAction>
                <Modal
                  title={t('release_desk_modal.title')}
                  open={isReleaseDeskOpen}
                  onCancel={() => {
                    setReleaseDeskOpen(false);
                  }}
                  footer={null}
                >
                  <ReleaseDeskModalProvider deskId={deskId} seriesId={seriesId}>
                    <ReleaseDeskModalContent
                      onClose={function (): void {
                        setReleaseDeskOpen(false);
                      }}
                    />
                  </ReleaseDeskModalProvider>
                </Modal>
              </>
            )}
          </CheckInButtonContainer>
        )}
      {!isAssignedAndShared && (
        <CheckInButtonContainer>
          <CheckInAction
            data-testid="edit-button"
            type="default"
            onClick={onEdit}
          >
            <EditAlt size={16} style={{ marginRight: '8px' }} />
            {t('check_in.edit')}
          </CheckInAction>
          <CheckInAction
            data-testid="cancel-button"
            type="default"
            danger
            onClick={onCancel}
          >
            <RedTrashIconContainer style={{ marginRight: '8px' }}>
              <TrashOutline />
            </RedTrashIconContainer>
            {reservationInProgress ? t('check_in.end') : t('check_in.cancel')}
          </CheckInAction>
        </CheckInButtonContainer>
      )}
    </CheckInContainer>
  );
}

const CheckInContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: var(--Space-Padding-paddingXS, 8px);
  flex-direction: column;
`;

const CheckInButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
  width: 100%;
`;

const CheckInAction = styled(Button)`
  display: flex;
  padding: 0px var(--Components-Button-Component-paddingInline, 16px);
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: var(--Space-Padding-paddingXS, 8px);
  flex-direction: row;
`;

const IconContainer = styled.div`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
`;

const InfoIcon = styled(InfoSolid)`
  width: 100%;
  height: 100%;
  fill-opacity: 0.55;
  position: absolute;
  top: 3px;
`;

const CheckmarkIcon = styled(SvgCheckmark)`
  top: 2px;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const DarkGreenText = styled(Text)`
  flex: 1;
  color: var(--utilities-positive-dark-green-110, #537a2f);
  word-wrap: break-word;
`;

const RedTrashIconContainer = styled.div`
  width: 16px;
  height: 16px;
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: #e81c1c !important;
    }
  }
`;
