import {
  useGetDeskEndTimesForSelectedDates,
  useGetStartTimesForSelectedDates,
  useTimezone,
} from 'atoms/resource';
import { useAuthContext } from 'contexts';
import { useMemo } from 'react';
import { createRecurrenceFromDates } from 'utils';

export const useMultiDayQueryVars = () => {
  const { currentUser } = useAuthContext();
  const { timezone } = useTimezone();

  const startTimes = useGetStartTimesForSelectedDates();
  const deskEndTimes = useGetDeskEndTimesForSelectedDates();

  const deskAvailabilityDuration = useMemo(() => {
    const startTime = startTimes?.[0];
    const endTime = deskEndTimes?.[0];

    return (
      (startTime && endTime && endTime.diff(startTime, 'minute')) || undefined
    );
  }, [deskEndTimes, startTimes]);

  const firstStartTime = startTimes?.[0];
  const firstEndTime = deskEndTimes?.[0];

  const recurrence =
    startTimes && startTimes?.length > 0
      ? createRecurrenceFromDates(startTimes)
      : undefined;

  return {
    skip: !startTimes || !deskAvailabilityDuration || !currentUser?.id,
    variables: {
      dates:
        startTimes?.map((x) => x.clone().tz(timezone).format('YYYY-MM-DD')) ||
        [],
      startTime: startTimes?.[0].clone().tz(timezone).format('H:m'),
      durationInMinutes: deskAvailabilityDuration || 15,
      timezone,
      userId: Number(currentUser?.id),
      startTimeMoment: firstStartTime?.toString() || '',
      endTimeMoment: firstEndTime?.toString() || '',
      recurrence: recurrence,
    },
  };
};
