import { atom } from 'recoil';
import { EditDeskView } from './types';

export const AtomKey = {
  editDeskReservationId: 'editDeskReservationId',
  editDeskNewDeskId: 'editDeskNewDeskId',
  editDeskPotentialDeskId: 'editDeskPotentialDeskId',
  editDeskView: 'editDeskView',
};

export const editDeskReservationId = atom<string | null>({
  key: AtomKey.editDeskReservationId,
  default: null,
});

export const editDeskNewDeskId = atom<string | null>({
  key: AtomKey.editDeskNewDeskId,
  default: null,
});

export const editDeskPotentialDeskId = atom<string | null>({
  key: AtomKey.editDeskPotentialDeskId,
  default: null,
});

export const editDeskView = atom<EditDeskView>({
  key: AtomKey.editDeskView,
  default: 'edit-summary',
});
