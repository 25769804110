import gql from 'graphql-tag';
import { useApolloContext } from 'contexts';
import {
  DeskResourceDetailsQuery,
  DeskResourceDetailsQueryVariables,
} from 'generated';
import { useQueryCachedLoad } from 'hooks';
import { useMultiDayQueryVars } from './useMultiDayVars';

export type DeskResourceDetailsReturn = {
  loading: boolean;
  deskDetails: DeskResourceDetails | null | undefined;
  deskAvailability: DeskResourceAvailability | undefined;
};

export type DeskResourceDetails = NonNullable<
  DeskResourceDetailsQuery['getAnyDeskById']
>;

export type DeskResourceAvailability = NonNullable<
  DeskResourceDetailsQuery['reservationsMultiDayGroupedByDate']
>[number];

export type DeskUnbookableReasons =
  DeskResourceAvailability['unbookableReasons'];

export type DeskAvailability = DeskResourceAvailability['availability'];

export type DeskSticker = DeskResourceDetails['stickers'][number];
export type DeskSetting = NonNullable<DeskResourceDetails['settings']>[number];

const DESK_RESOURCE_DETAILS = gql`
  query DeskResourceDetails(
    $deskId: ID!
    $deskIds: [ID!]!
    $dates: [LocalDate!]!
    $startTime: LocalTime!
    $durationInMinutes: Int!
    $timezone: IANATimezone!
    $userId: Int!
    $startTimeMoment: Date!
    $endTimeMoment: Date!
    $recurrence: String
  ) {
    getAnyDeskById(id: $deskId) {
      id
      name
      rawType
      isReservable
      isDisabled
      level {
        name
      }
      location {
        name
      }
      stickers {
        id
      }
      settings {
        slug
        value
      }
      state(
        startTime: $startTimeMoment
        endTime: $endTimeMoment
        userId: $userId
        recurrence: $recurrence
      ) {
        reservations {
          startTime
        }
        exclusions {
          startTime
          endTime
        }
      }
    }
    reservationsMultiDayGroupedByDate(
      deskIds: $deskIds
      dates: $dates
      startTime: $startTime
      durationInMinutes: $durationInMinutes
      timezone: $timezone
      userId: $userId
    ) {
      deskId
      availability
      unbookableReasons {
        reason
        value
      }
    }
  }
`;

export const useDeskResourceDetails = (deskId: string | null | undefined) => {
  const { tenantId } = useApolloContext();
  const { skip, variables } = useMultiDayQueryVars();

  const { loading, data } = useQueryCachedLoad<
    DeskResourceDetailsQuery,
    DeskResourceDetailsQueryVariables
  >(
    DESK_RESOURCE_DETAILS,
    {
      context: {
        headers: {
          'cache-refresh': 'no-cache',
        },
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !tenantId || !deskId || skip,
      variables: {
        deskId: deskId || '',
        deskIds: [deskId || ''],
        ...variables,
      },
    },
    [deskId]
  );

  // This query returns an array as you can request with multiple desks
  // Since we are only requesting for a single desk return the first in the list
  const availabilityForRequestedDesk =
    data?.reservationsMultiDayGroupedByDate?.[0];

  return {
    loading,
    deskDetails: data?.getAnyDeskById,
    deskAvailability: availabilityForRequestedDesk,
  };
};
