import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  editDeskReservationId,
  editDeskNewDeskId,
  editDeskView,
  editDeskPotentialDeskId,
} from './atoms';
import { useSetRightSidebarView } from 'atoms/sidebar/hooks';
import { EditDeskView } from './types';

export const useEditDeskReservationId = () => {
  return useRecoilValue(editDeskReservationId);
};

export const useSetEditDeskReservationId = () => {
  return useSetRecoilState(editDeskReservationId);
};

export const useEditDeskPotentialDeskId = () => {
  return useRecoilValue(editDeskPotentialDeskId);
};

export const useSetEditDeskPotentialDeskId = () => {
  return useSetRecoilState(editDeskPotentialDeskId);
};

export const useEditDeskNewDeskId = () => {
  return useRecoilValue(editDeskNewDeskId);
};

export const useSetEditDeskNewDeskId = () => {
  return useSetRecoilState(editDeskNewDeskId);
};

export const useEditDeskView = () => {
  return useRecoilValue(editDeskView);
};

export const useSetEditDeskView = () => {
  const setSidebarView = useSetRightSidebarView();
  const setDeskView = useSetRecoilState(editDeskView);
  return (view: EditDeskView) => {
    setSidebarView('desks');
    setDeskView(view);
  };
};
