import { useMemo, useCallback, useState } from 'react';
import moment, { duration } from 'moment';
import { message } from '@robinpowered/ui-kit';
import { DeskConfirmationType } from 'generated';
import { useTranslation } from 'react-i18next';
import { useConfirmDesk } from '../../graphql/useConfirmDeskReservation';
import { DeskSettings, DeskSettingSlug } from '../../graphql/useDeskSettings';
import { momentToLocationDateTime } from 'utils';
import { Reservation } from '../../graphql';
import { useTimezone } from 'atoms/resource';

export function useReservationActions(deskSettings: DeskSettings) {
  const { t } = useTranslation('resourceDetails');
  const { timezone } = useTimezone();
  const [checkinLoading, setCheckinLoading] = useState(false);
  const [confirmDesk] = useConfirmDesk();

  const allowCheckIns = useMemo(
    () =>
      deskSettings[DeskSettingSlug.AssignedReservationCheckInsEnabled] ===
      'true',
    [deskSettings]
  );

  const enforceLocalCheckInOnly = useMemo(
    () => deskSettings[DeskSettingSlug.EnforceLocalCheckInOnly] === 'true',
    [deskSettings]
  );

  const allowExclusions = useMemo(
    () => deskSettings[DeskSettingSlug.AllowExclusions] === 'true',
    [deskSettings]
  );

  const getCheckInStart = useCallback(
    (reservation: Reservation) => {
      const reservationStart = momentToLocationDateTime(
        moment(reservation.startTime),
        timezone
      );
      const checkInWindow =
        deskSettings[DeskSettingSlug.SeatReservationCheckInWindow];
      const checkInDuration = duration(checkInWindow);
      return checkInDuration.isValid()
        ? reservationStart.subtract(checkInDuration)
        : reservationStart;
    },
    [deskSettings, timezone]
  );

  const getDeskAbandonedAt = useCallback(
    (reservation: Reservation) => {
      const abandonmentEnabled =
        deskSettings[DeskSettingSlug.AbandonedDeskProtectionEnabled] === 'true';
      if (!abandonmentEnabled) {
        return null;
      }

      const reservationStart = momentToLocationDateTime(
        moment(reservation.startTime),
        timezone
      );
      const abandonmentThreshold =
        deskSettings[DeskSettingSlug.AbandonedDeskProtectionThreshold];
      const abandonmentDuration = duration(abandonmentThreshold);
      return abandonmentDuration.isValid()
        ? reservationStart.add(abandonmentDuration)
        : null;
    },
    [deskSettings, timezone]
  );

  const handleCheckIn = useCallback(
    async (reservationId: string) => {
      setCheckinLoading(true);
      const minimumTimeout = new Promise((resolve) => setTimeout(resolve, 750));

      try {
        await Promise.all([
          confirmDesk({
            variables: {
              reservationId,
              type: DeskConfirmationType.Dashboard,
            },
          }),
          minimumTimeout,
        ]);

        setCheckinLoading(false);
        await message.success(t('check_in.success_message'));
      } catch (error) {
        await minimumTimeout;
        setCheckinLoading(false);
        await message.error(t('check_in.error_message'));
      }
    },
    [confirmDesk, t]
  );

  const hasReservationStarted = useCallback((reservation) => {
    return moment()
      .tz(reservation.timeZone)
      .isSameOrAfter(moment.tz(reservation.startTime, reservation.timeZone));
  }, []);

  return {
    allowCheckIns,
    enforceLocalCheckInOnly,
    allowExclusions,
    getCheckInStart,
    getDeskAbandonedAt,
    handleCheckIn,
    checkinLoading,
    setCheckinLoading,
    hasReservationStarted,
  };
}
