import { ChevronLeftOutline } from '@robinpowered/icons';
import { SidebarContent, SidebarHeader } from 'components/global/sidebar';
import { DeskList } from '../desk-list/DeskList';
import { Alert, Button } from '@robinpowered/ui-kit';
import styled from '@emotion/styled';
import { useCallback } from 'react';
import { useSetEditDeskView } from 'atoms/editDesk';
import { useTranslation } from 'react-i18next';

export const DeskListView = () => {
  const { t } = useTranslation('resourceDetails');

  const setView = useSetEditDeskView();

  const onClose = useCallback(() => {
    setView('edit-summary');
  }, [setView]);

  return (
    <>
      <SidebarHeader
        prefix={
          <BackButton type="link" onClick={onClose} data-testid="back-button">
            <ChevronLeftOutline size={16} />
          </BackButton>
        }
        header={t('edit_desk_reservation.desk_list_header')}
        onClose={onClose}
      />

      <SidebarContent>
        <Alert
          message="Under Construction. Desk list is in progress."
          type="warning"
          style={{ marginBottom: 24 }}
        />

        <DeskList />
      </SidebarContent>
    </>
  );
};

const BackButton = styled(Button)`
  padding: 0;
`;
