import { useRecoilCallback } from 'recoil';
import {
  handleSetLeftSidebarOpen,
  handleSetRightSidebarView,
} from './handlers';

export const useSetRightSidebarView = () => {
  return useRecoilCallback(handleSetRightSidebarView);
};

export const useSetLeftSidebarOpen = () => {
  return useRecoilCallback(handleSetLeftSidebarOpen);
};
