import {
  FlyToResourceComponent,
  ResetCameraComponent,
  MapMousePointer,
  MouseClickComponent,
  MouseOverComponent,
  OnMapClickEvent,
  ReactMapGl,
  SpaceStateHoverComponent,
  SelectResource,
} from '@robinpowered/perseus';
import {
  useSetEditDeskPotentialDeskId,
  useSetEditDeskView,
} from 'atoms/editDesk';
import {
  useIsCameraResetting,
  useMapInteractiveLayers,
  useMapMode,
  useSetIsCameraResetting,
} from 'atoms/mapInteractions';
import {
  useCurrentlySelectedResource,
  useSetCurrentlySelectedResource,
} from 'atoms/resource';
import { SelectedResource } from 'atoms/resource/types';
import { useSetRightSidebarView } from 'atoms/sidebar/hooks';
import { useSetEditSpaceView } from 'atoms/space';
import { useCallback, useMemo, useState } from 'react';
import { checkIsExhaustive, getClickedResource } from 'utils';

export const MapInteractions = () => {
  const mapInteractiveLayers = useMapInteractiveLayers();

  const isCameraResetting = useIsCameraResetting();
  const setIsCameraResetting = useSetIsCameraResetting();

  const currentlySelectedResource = useCurrentlySelectedResource();
  const setCurrentlySelectedResource = useSetCurrentlySelectedResource();
  const setEditDeskPotentialDeskId = useSetEditDeskPotentialDeskId();
  const setRightSidebarView = useSetRightSidebarView();
  const setDeskSidebarView = useSetEditDeskView();
  const setSpaceSidebarView = useSetEditSpaceView();
  const mapMode = useMapMode();

  const seatStateFilter = useMemo(() => {
    if (mapMode === 'edit-desk-reservation') {
      return {
        available: true,
      };
    }
  }, [mapMode]);

  const { id: selectedResourceId, type: selectedResourceType } =
    useCurrentlySelectedResource() || {};
  const [currentlySelectedResourceForFly, setCurrentlySelectedResourceForFly] =
    useState<string>();

  const onCameraResetComplete = useCallback(() => {
    setIsCameraResetting(false);
  }, [setIsCameraResetting]);

  const handleBrowseModeClick = useCallback(
    (resource: SelectedResource | undefined) => {
      if (!resource) {
        setCurrentlySelectedResource(null);
        setCurrentlySelectedResourceForFly(undefined);
        setRightSidebarView(undefined);
      } else {
        setCurrentlySelectedResource(resource);
        setCurrentlySelectedResourceForFly(resource.id);
        if (resource.type === 'seats') {
          setDeskSidebarView('desk-details');
        } else {
          setSpaceSidebarView('space-details');
        }
      }
    },
    [
      setCurrentlySelectedResource,
      setDeskSidebarView,
      setRightSidebarView,
      setSpaceSidebarView,
    ]
  );

  const handleEditDeskModeClick = useCallback(
    (resource: SelectedResource | undefined) => {
      if (
        resource?.type === 'seats' &&
        resource.id !== currentlySelectedResource?.id
        // TODO: Handle unavailable desks
      ) {
        setEditDeskPotentialDeskId(resource.id);
        setDeskSidebarView('potential-desk');
      }
    },
    [
      currentlySelectedResource?.id,
      setEditDeskPotentialDeskId,
      setDeskSidebarView,
    ]
  );

  const handleMapClick = useCallback(
    (e: OnMapClickEvent) => {
      const resource = getClickedResource(e);

      switch (mapMode) {
        case 'browse':
          handleBrowseModeClick(resource);
          break;

        case 'edit-desk-reservation':
          handleEditDeskModeClick(resource);
          break;

        default:
          checkIsExhaustive(mapMode);
      }
    },
    [handleBrowseModeClick, handleEditDeskModeClick, mapMode]
  );

  const handleFlyComplete = useCallback(() => {
    setCurrentlySelectedResourceForFly(undefined);
  }, []);

  return (
    <>
      <MouseOverComponent mouseOverSourceIdentity={mapInteractiveLayers}>
        <SpaceStateHoverComponent />
        {/* <SeatStateHoverComponent /> */}
        <MapMousePointer
          mouseOverSourceIdentity={mapInteractiveLayers}
          defaultCursorStyle="default"
        />
      </MouseOverComponent>

      <SpaceStateHoverComponent />

      {/** Map Navigation Control  */}
      <ReactMapGl.NavigationControl position="top-right" />

      {/** Seat + space mouse click control */}
      <MouseClickComponent
        mouseClickSourceIdentity={mapInteractiveLayers}
        onClick={handleMapClick}
        seatStateFilter={seatStateFilter}
      />

      <SelectResource
        selectedResource={
          selectedResourceId
            ? {
                id: selectedResourceId,
                type: selectedResourceType as 'seats' | 'spaces',
              }
            : undefined
        }
      />

      {/* Fly to resource on click components  */}
      {mapInteractiveLayers.has('seats') && (
        <FlyToResourceComponent
          resourceLayer="seats"
          resourceId={currentlySelectedResourceForFly}
          onFlyCompleteOrInterrupted={handleFlyComplete}
          zoomTo={6}
        />
      )}

      {(mapInteractiveLayers.has('spaces') ||
        mapInteractiveLayers.has('space_labels')) && (
        <FlyToResourceComponent
          resourceLayer="spaces"
          resourceId={currentlySelectedResourceForFly}
          onFlyCompleteOrInterrupted={handleFlyComplete}
          zoomTo={4}
        />
      )}

      {isCameraResetting && (
        <ResetCameraComponent
          onCameraResetCompleteOrInterrupted={onCameraResetComplete}
        />
      )}
    </>
  );
};
