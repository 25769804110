/* eslint-disable @shopify/jsx-no-hardcoded-content */
import styled from '@emotion/styled';
import {
  // Button,
  Typography,
  Skeleton,
  Image,
  Alert,
} from '@robinpowered/ui-kit';
import { SpaceSolid } from '@robinpowered/icons';
import {
  useCurrentlySelectedResource,
  useSetCurrentlySelectedResource,
} from '../../../atoms/resource';
import { useSpaceResourceDetails } from './graphql/useSpaceResourceDetails';
import { SpaceAmenities } from './SpaceAmenities';
import { GroupSolid, HandicapAccessibleSolid } from '@robinpowered/icons';
// import { ResourceTags } from '../ResourceTags';
// import { useTranslation } from 'react-i18next';
import { SpaceBookingPolicies } from './SpaceBookingPolicies';
import SpaceAvailabilityPills from './space-availability-pills/SpaceAvailabilityPills';
import { useSetRecoilState } from 'recoil';
import { rightSidebarView } from 'atoms/sidebar/atoms';
import { useCallback } from 'react';
import { SidebarContent, SidebarHeader } from 'components/global/sidebar';

const { Title, Text } = Typography;

export const SpaceDetails = () => {
  // const { t } = useTranslation('resourceDetails');
  const { type: selectedResourceType, id: selectedResourceId } =
    useCurrentlySelectedResource() || {};
  const setCurrentlySelectedResource = useSetCurrentlySelectedResource();
  const setRightSidebarView = useSetRecoilState(rightSidebarView);

  const onClose = useCallback(() => {
    setCurrentlySelectedResource(null);
    setRightSidebarView(undefined);
  }, [setCurrentlySelectedResource, setRightSidebarView]);
  const { spaceDetails, loading } = useSpaceResourceDetails(selectedResourceId);
  if (loading) {
    return (
      <SpaceDetailsContent data-testid="skeleton">
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </SpaceDetailsContent>
    );
  }

  if (!spaceDetails || selectedResourceType !== 'spaces') {
    return null;
  }

  return (
    <>
      <SidebarHeader header={'Space Details'} onClose={onClose} />
      <SidebarContent>
        <SpaceDetailsContent>
          {spaceDetails.image && (
            <Image data-testid="space-image" src={spaceDetails.image} />
          )}
          <SpaceTitle>
            <SpaceIcon>
              <SpaceSolid />
            </SpaceIcon>
            <SpaceTitleWrapper>
              <SpaceName data-testid="space-name" level={4}>
                {spaceDetails.name}
              </SpaceName>
              {/* <SpaceLocation data-testid="space-location" type="secondary">
            Floor, Building
          </SpaceLocation> */}
            </SpaceTitleWrapper>
          </SpaceTitle>
          <SpaceSubDetails>
            <CapacityContainer>
              <Group />
              <Text>Fits: {spaceDetails.capacity}</Text>
            </CapacityContainer>
            {spaceDetails.isAccessible ?? (
              <CapacityContainer>
                <Handicap />
                <Text>Accessible</Text>
              </CapacityContainer>
            )}
          </SpaceSubDetails>
          <Alert
            message="Under Construction. Space details and booking is in progress."
            type="warning"
          />
          {/* <BookingControls>
        <BookingControlsCallToActions>
          <BookButton type="primary">New event</BookButton>
        </BookingControlsCallToActions>
      </BookingControls>*/}
          <SpaceAvailabilityPills spaceId={spaceDetails.id} />
          <SpaceAmenities spaceId={spaceDetails.id} />
          {/* <ServicesContainer>
        <ResourceTags
          title={t('services')}
          resources={[
            { id: '1', name: 'AV and Tech' },
            { id: '2', name: 'Facilities' },
          ]}
        />
      </ServicesContainer> */}
          <SpaceBookingPolicies spaceId={spaceDetails.id} />
          {/* <CallToActions>
        <Actions>
          <Action onClick={() => {}}>Report issue</Action>
        </Actions>
      </CallToActions> */}
        </SpaceDetailsContent>
      </SidebarContent>
    </>
  );
};

const SpaceDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--main-colors-white-white-0, #fff);
`;

const SpaceTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

const SpaceIcon = styled.div`
  display: flex;
  width: 44px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--Color-Bg-Primary, #f7f6f6);
`;

const SpaceTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
`;

const SpaceName = styled(Title)`
  && {
    margin: 0;
  }
`;

const SpaceSubDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
  width: 182px;
`;

const Handicap = styled(HandicapAccessibleSolid)`
  width: 16px;
  height: 16px;
`;

const Group = styled(GroupSolid)`
  width: 16px;
  height: 16px;
`;

const CapacityContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
`;
