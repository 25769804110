import styled from '@emotion/styled';
import { Button, message, Skeleton, Typography } from '@robinpowered/ui-kit';
import {
  useGetSpaceEndTimesForSelectedDates,
  useGetStartTimesForSelectedDates,
  useTimezone,
} from 'atoms/resource';
import { momentToLocationDateTime } from 'utils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAvailabilityPillStyles } from './hooks';
import { AvailabilityPill } from './SpaceAvailabilityPill';
import { useSpaceWindowedAvailability } from './graphql';
import { useAuthContext } from 'contexts';
import { redirectToOldComposer } from 'utils/legacyDashboard';
import { useState } from 'react';

export const CUSTOM_SHORT_TIME_FORMAT_WITH_ZONE = 'h:mm a';
export const CUSTOM_SHORT_TIME_FORMAT = 'h:mm';
export const CUSTOM_VERY_SHORT_TIME_FORMAT = 'h';

const { Title } = Typography;

type AvailabilityDetailsProps = {
  spaceId: string;
};

const SpaceAvailabilityPills = ({ spaceId }: AvailabilityDetailsProps) => {
  const { t } = useTranslation('resourceDetails');
  const { timezone } = useTimezone();
  const { currentOrg } = useAuthContext();
  const endTimes = useGetSpaceEndTimesForSelectedDates();
  const startTimes = useGetStartTimesForSelectedDates();

  const pillStyles = useAvailabilityPillStyles();

  const [isRedirecting, setIsRedirecting] = useState(false);

  const { windowedAvailability, loading } = useSpaceWindowedAvailability(
    spaceId,
    startTimes?.[0]
  );

  const handlePillClick = (start: string, end: string) => {
    if (!currentOrg || currentOrg.slug === '') {
      void message.error(t('error'));
      return;
    }

    setIsRedirecting(true);

    redirectToOldComposer(
      currentOrg?.slug ?? '',
      spaceId,
      momentToLocationDateTime(moment(start), timezone),
      momentToLocationDateTime(moment(end), timezone)
    );
  };

  const onViewMore = () => {
    if (
      !currentOrg ||
      currentOrg.slug === '' ||
      !startTimes?.[0] ||
      !endTimes?.[0]
    ) {
      void message.error(t('common.error'));
      return;
    }
    setIsRedirecting(true);

    redirectToOldComposer(
      currentOrg?.slug ?? '',
      spaceId,
      startTimes[0],
      endTimes[0]
    );
  };

  const renderAvailabilityPills = () => {
    if (loading) {
      return <Skeleton />;
    }

    return windowedAvailability?.map((slot, index) => {
      const { start, end, status } = slot;

      if (!start || !end) {
        return null;
      }

      const isDisabled = status !== 'AVAILABLE';
      const startMoment = moment(start);
      const startTimeFormat =
        startMoment.minutes() === 0
          ? CUSTOM_VERY_SHORT_TIME_FORMAT
          : CUSTOM_SHORT_TIME_FORMAT;
      const startTime =
        index === 0
          ? t('space_availability.now')
          : momentToLocationDateTime(startMoment, timezone).format(
              startTimeFormat
            );
      const endTime = momentToLocationDateTime(moment(end), timezone).format(
        CUSTOM_SHORT_TIME_FORMAT_WITH_ZONE
      );
      const timeRange = `${startTime} - ${endTime}`;

      return (
        <AvailabilityPill
          key={index}
          disabled={isDisabled || isRedirecting}
          {...pillStyles}
          onClick={() => handlePillClick(start, end)}
        >
          {timeRange}
        </AvailabilityPill>
      );
    });
  };

  return (
    <AvailbilityDetails>
      <Title level={5}>{t('space_availability.availability')}</Title>
      <AvailabilityPillsContainer>
        {renderAvailabilityPills()}
      </AvailabilityPillsContainer>
      <ViewScheduleButton onClick={onViewMore} disabled={isRedirecting}>
        {t('space_availability.view_more_times')}
      </ViewScheduleButton>
    </AvailbilityDetails>
  );
};

export default SpaceAvailabilityPills;

const AvailbilityDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
  max-width: 100%;
  box-sizing: border-box;
`;

const AvailabilityPillsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 8px;
  align-self: stretch;
  max-width: 100%;
  box-sizing: border-box;
`;

const ViewScheduleButton = styled(Button)`
  display: flex;
  padding: 0px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
