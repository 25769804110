import { atom } from 'recoil';

export type SidebarView =
  | undefined
  | 'composer'
  //TODO: revisit if this is the right place for this once we have issue reporting for spaces
  | 'report'
  | 'desks'
  | 'spaces';

export const leftSidebarOpenState = atom<boolean>({
  key: 'leftSidebarOpenState',
  default: false,
});

export const rightSidebarView = atom<SidebarView>({
  key: 'rightSidebarView',
  default: undefined,
});
