import { Collapse, CollapseProps, Skeleton } from '@robinpowered/ui-kit';
import { useDesksOnFloor } from '../graphql/useDesksOnFloor';
import { useMapControlsContext } from 'contexts';
import { useMemo } from 'react';
import { useBookingEnabledForDesks } from '../hooks/useBookingEnabledForDesks';
import { ZoneAvailability } from './ZoneAvailability';
import { ZoneList } from './ZoneList';

export const DeskList = () => {
  const { selectedLevelId } = useMapControlsContext();
  const { loading, data } = useDesksOnFloor(selectedLevelId);

  const { data: desksWithAvailability, loading: desksWithAvailabilityLoading } =
    useBookingEnabledForDesks(
      data?.getLevelById?.zones.flatMap((zone) =>
        zone.desks.map((desk) => desk.id)
      )
    );

  const zonesWithAvailability = useMemo(() => {
    if (loading || desksWithAvailabilityLoading || !data?.getLevelById) {
      return [];
    }

    return data.getLevelById.zones.map((zone) => ({
      ...zone,
      desks: zone.desks.map((desk) => ({
        ...desk,
        isBookable: desksWithAvailability?.[desk.id]?.isBookable || false,
      })),
    }));
  }, [data, desksWithAvailability, desksWithAvailabilityLoading, loading]);

  const items: CollapseProps['items'] = useMemo(() => {
    return zonesWithAvailability?.map((zone) => ({
      label: zone.name,
      children: <ZoneList desks={zone.desks} />,
      extra: <ZoneAvailability zone={zone} />,
    }));
  }, [zonesWithAvailability]);

  return (
    <>
      {loading && (
        <div data-testid="skeleton">
          <Skeleton />
        </div>
      )}

      {!loading && (
        <>
          {/* TODO: Search bar */}

          <Collapse items={items} />
        </>
      )}
    </>
  );
};
