import {
  useGetStartTimesForSelectedDates,
  useGetDeskEndTimesForSelectedDates,
} from 'atoms/resource';
import { useDeskDetails } from './graphql/useDeskDetails';
import moment from 'moment';
import { useDeskSettings } from './graphql/useDeskSettings';
import { useAuthContext } from 'contexts';
import { useSharedDeskSchedule } from './graphql/useSharedDeskSchedule';
import { Skeleton } from '@robinpowered/ui-kit';
import { useDeskReservationsBySeatId } from './graphql';
import { ScheduledReservations } from './reservation/ScheduledReservations';
import { NonScheduledReservations } from './reservation/NonScheduledReservations';

export function DeskReservations({
  deskId,
}: {
  deskId: string | undefined;
}): JSX.Element | null {
  const { currentUser } = useAuthContext();
  const startTime = useGetStartTimesForSelectedDates();
  const endTime = useGetDeskEndTimesForSelectedDates();

  const durationInMinutes =
    endTime && startTime
      ? moment(endTime[0]).diff(moment(startTime[0]), 'minutes')
      : 480;

  const { deskDetails: desk, loading: deskLoading } = useDeskDetails(deskId);

  const { reservations, loading: reservationsLoading } =
    useDeskReservationsBySeatId(
      deskId,
      durationInMinutes,
      startTime ? startTime[0] : undefined
    );

  const { deskSettings, loading: settingsLoading } = useDeskSettings(deskId);

  const { data: deskSchedule, loading: scheduleLoading } =
    useSharedDeskSchedule(deskId, desk?.rawType);

  if (deskLoading || reservationsLoading || settingsLoading || scheduleLoading)
    return <Skeleton active />;

  return (
    <>
      <ScheduledReservations
        deskSchedules={deskSchedule}
        reservations={reservations}
        deskSettings={deskSettings}
        desk={desk}
        currentUser={currentUser}
      />
      {startTime && startTime?.length === 1 && (
        <NonScheduledReservations
          deskSchedules={deskSchedule}
          reservations={reservations}
          deskSettings={deskSettings}
          desk={desk}
          currentUser={currentUser}
        />
      )}
    </>
  );
}
