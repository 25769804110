const env = process.env.NODE_ENV || 'local';

type ConfigType = {
  local: string[];
  staging: string[];
  production: string[];
};

const config: ConfigType = {
  local: [], // Disable tracing origins on local
  staging: [
    'https://federation-gateway.staging.robinpowered.com',
    'https://atlas.staging.services.robinpowered.com',
  ],
  production: [
    'https://federation-gateway.robinpowered.com',
    'https://atlas.services.robinpowered.com',
  ],
};

const datadogOrigins = config[env as keyof ConfigType];

export { datadogOrigins };
