import {
  UseSeriesStartTimesQuery,
  UseSeriesStartTimesQueryVariables,
} from 'generated';
import gql from 'graphql-tag';
import { useQueryCachedLoad } from 'hooks';

const GET_SERIES_INSTANCES = gql`
  query useSeriesStartTimes($seriesId: ID!) {
    getDeskReservationInstancesById(id: $seriesId) {
      id
      startTime
    }
  }
`;

export const useSeriesStartTimes = (seriesId: string | null | undefined) => {
  const { data, loading } = useQueryCachedLoad<
    UseSeriesStartTimesQuery,
    UseSeriesStartTimesQueryVariables
  >(
    GET_SERIES_INSTANCES,
    {
      skip: !seriesId,
      variables: { seriesId: seriesId || '' },
    },
    [seriesId]
  );

  return {
    loading,
    startTimes: data?.getDeskReservationInstancesById.map((i) => i.startTime),
  };
};
