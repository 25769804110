import { useTranslation } from 'react-i18next';
import { Tag } from '@robinpowered/ui-kit';
import { DeskWithAvailability } from './types';

export const ZoneAvailability = ({
  zone,
}: {
  zone: { desks: DeskWithAvailability[] };
}) => {
  const { t } = useTranslation('resourceDetails');

  return (
    <Tag>
      {t('desk_list.available_desks', {
        available: zone.desks.filter((desk) => desk.isBookable).length,
        total: zone.desks.length,
      })}
    </Tag>
  );
};
