import { FC } from 'react';
import { EditSummaryView } from './edit-desk-reservation/EditSummaryView';
import { checkIsExhaustive } from 'utils';
import { useEditDeskView } from 'atoms/editDesk';
import { EditDeskDetailsView } from './edit-desk-reservation/EditDeskDetailsView';
import { DeskListView } from './edit-desk-reservation/DeskListView';
import { DeskDetails } from './DeskDetails';

export const DeskSidebar: FC = () => {
  const view = useEditDeskView();
  switch (view) {
    case 'edit-summary':
      return <EditSummaryView />;
    case 'current-desk':
    case 'new-desk':
    case 'potential-desk':
      return <EditDeskDetailsView />;
    case 'desk-list':
      return <DeskListView />;
    case 'desk-details':
      return <DeskDetails />;

    default:
      checkIsExhaustive(view);
      return null;
  }
};
