import {
  FC,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { setContext } from '@apollo/client/link/context';
import { useAccessToken } from 'hooks/useAccessToken';
import { sha256 } from 'crypto-hash';
import { config } from 'config';
import {
  generateGraphQLHeaders,
  ClientHeadersByApp,
} from '@robinpowered/dashboard-apps-common';
import fetch from 'cross-fetch';
import { v4 as uuidv4 } from 'uuid';
import { datadogRum } from '@datadog/browser-rum';

type ApolloContextValue = {
  tenantId: string | undefined;
  setTenantId: Dispatch<SetStateAction<string | undefined>>;
};

const httpLink = createHttpLink({
  uri: config.gqlUrl ?? '',
  fetch,
});

let persistedQueryLink: ApolloLink | undefined;

try {
  // IE11 & Edge will not be able to use crypto, so only add persisted queries
  // where it's available.
  if (
    typeof window?.crypto?.subtle?.digest !== 'undefined' &&
    typeof window?.TextEncoder !== 'undefined'
  ) {
    /**
     * Persisted query link allows us to reduce the overhead
     * of repeated requests by sharing a request hash with the server.
     * This link also improves compatibility with CDN access by allowing
     * graphql over GET requests
     */
    persistedQueryLink = createPersistedQueryLink({
      sha256,
      useGETForHashedQueries: true,
      disable: (error) => {
        if (error.networkError) {
          return true;
        }
        return false;
      },
    });
  }
} catch (e) {} // eslint-disable-line no-empty

const datadogLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-datadog-trace-id': uuidv4(),
    },
  }));

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const captureException = (message: string) => {
    datadogRum.addError(new Error(message ?? 'Unknown error'), {
      operation,
    });
  };

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      captureException(
        `[GraphQL error]: Message: ${message} - {locations: ${JSON.stringify(
          locations
        )}, path: ${path}}`
      );
    });
  }
  if (networkError) {
    captureException(`[Network error]: ${networkError}`);
  }
});

const createApolloClient = (
  accessToken: string | null,
  tenantId: string | undefined
) => {
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...generateGraphQLHeaders(
        accessToken,
        tenantId,
        ClientHeadersByApp.OFFICE
      ),
      ...headers,
    },
  }));

  return new ApolloClient({
    name: config.appName ?? 'unknown-react-dashboard-app',
    version: config.appVersion ?? '0.0',
    link: from([
      errorLink,
      authLink,
      datadogLink,
      ...(persistedQueryLink ? [persistedQueryLink] : []),
      httpLink,
    ]),
    //TODO - what cache configs should we have here? Just setting this default for new app setup
    cache: new InMemoryCache(),
    credentials: 'include',
    resolvers: {},
  });
};

const ApolloContext = createContext<ApolloContextValue>({
  tenantId: undefined,
  setTenantId: () => null,
});

export const ApolloContextProvider: FC = ({ children }) => {
  const [tenantId, setTenantId] = useState<string | undefined>();
  const accessToken = useAccessToken();

  const apolloClient = useMemo(
    () => createApolloClient(accessToken, tenantId),
    [accessToken, tenantId]
  );

  return (
    <ApolloContext.Provider value={{ tenantId, setTenantId }}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </ApolloContext.Provider>
  );
};

export const useApolloContext = (): ApolloContextValue => {
  return useContext(ApolloContext);
};
