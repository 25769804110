import { useCallback, useMemo } from 'react';
import {
  Availability,
  BookingEnabledQuery,
  BookingEnabledQueryVariables,
  DeskType,
} from 'generated';
import moment from 'moment';
import { useGetStartTimesForSelectedDates } from 'atoms/resource';
import { gql } from '@apollo/client';
import { useMultiDayQueryVars } from '../graphql/useMultiDayVars';
import { useApolloContext } from 'contexts/ApolloContext';
import { useQueryCachedLoad } from 'hooks';

type DeskDetails = BookingEnabledQuery['getDesksByIds'][number];
type DeskDetailsState = DeskDetails['state'];
type DeskAvailability =
  BookingEnabledQuery['reservationsMultiDayGroupedByDate'][number]['availability'];

type DeskWithAvailability = DeskDetails & {
  availability: DeskAvailability | undefined;
};

type DeskBookingEnabledMap = {
  [key: string]: {
    isBookable: boolean;
    isSelectedStartTimeDuringExclusion: boolean;
  };
};

const BOOKING_ENABLED_QUERY = gql`
  query BookingEnabled(
    $deskIds: [ID!]!
    $dates: [LocalDate!]!
    $startTime: LocalTime!
    $durationInMinutes: Int!
    $timezone: IANATimezone!
    $userId: Int!
    $startTimeMoment: Date!
    $endTimeMoment: Date!
    $recurrence: String
  ) {
    getDesksByIds(ids: $deskIds) {
      id
      rawType
      state(
        startTime: $startTimeMoment
        endTime: $endTimeMoment
        userId: $userId
        recurrence: $recurrence
      ) {
        exclusions {
          startTime
          endTime
        }
      }
    }
    reservationsMultiDayGroupedByDate(
      deskIds: $deskIds
      dates: $dates
      startTime: $startTime
      durationInMinutes: $durationInMinutes
      timezone: $timezone
      userId: $userId
    ) {
      deskId
      availability
    }
  }
`;

export const useBookingEnabledForDesks = (
  deskIds: string[] | null | undefined
) => {
  const { tenantId } = useApolloContext();
  const { skip, variables } = useMultiDayQueryVars();

  const startTimes = useGetStartTimesForSelectedDates();

  const { data, loading } = useQueryCachedLoad<
    BookingEnabledQuery,
    BookingEnabledQueryVariables
  >(BOOKING_ENABLED_QUERY, {
    context: {
      headers: {
        'cache-refresh': 'no-cache',
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !tenantId || !deskIds?.length || skip,
    variables: {
      deskIds: deskIds || [],
      ...variables,
    },
  });

  const [deskDetails, deskAvailability] = useMemo(() => {
    return [data?.getDesksByIds, data?.reservationsMultiDayGroupedByDate];
  }, [data]);

  const desksWithAvailability: DeskWithAvailability[] | undefined =
    useMemo(() => {
      return deskDetails?.map((desk) => {
        return {
          ...desk,
          availability: deskAvailability?.find(
            (avail) => avail.deskId === desk.id
          )?.availability,
        };
      });
    }, [deskDetails, deskAvailability]);

  const isAssigned = useCallback((rawType: DeskType[]) => {
    return (
      rawType?.length === 2 &&
      rawType.includes(DeskType.Assigned) &&
      rawType.includes(DeskType.Shared)
    );
  }, []);

  const isNotAssignedAndAvailable = useCallback((availability, isAssigned) => {
    const isAvailable = availability === Availability.Available;
    return !isAssigned && isAvailable;
  }, []);

  const isSelectedStartTimeDuringExclusion = useCallback(
    (deskDetailsState: DeskDetailsState) => {
      if (
        !deskDetailsState.exclusions ||
        deskDetailsState.exclusions.length === 0
      ) {
        return false;
      }

      return deskDetailsState.exclusions.some((exclusion) => {
        const exclusionStart = moment(exclusion.startTime);
        const exclusionEnd = moment(exclusion.endTime);

        return startTimes?.[0]?.isBetween(exclusionStart, exclusionEnd);
      });
    },
    [startTimes]
  );

  const isBookable = useCallback(
    (desk: DeskWithAvailability) => {
      const assigned = isAssigned(desk.rawType);
      const notAssignedAndAvailable = isNotAssignedAndAvailable(
        desk.availability,
        assigned
      );
      const startTimeDuringExclusion = isSelectedStartTimeDuringExclusion(
        desk.state
      );

      return (
        notAssignedAndAvailable ||
        (desk?.availability === Availability.Available &&
          startTimeDuringExclusion)
      );
    },
    [isAssigned, isNotAssignedAndAvailable, isSelectedStartTimeDuringExclusion]
  );

  return {
    loading,
    data: desksWithAvailability?.reduce<DeskBookingEnabledMap>((acc, desk) => {
      acc[desk.id] = {
        isBookable: isBookable(desk),
        isSelectedStartTimeDuringExclusion: isSelectedStartTimeDuringExclusion(
          desk.state
        ),
      };
      return acc;
    }, {}),
  };
};
